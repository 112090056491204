export const SLOT_EXHIBITOR_CREATE_MEETING_FRAGMENT = `
  fragment slotExhibitorCreateMeetingFragment on Exhibitor {
    uid
    slots: _slots(filter: { code: "meeting_feature" }) {
      uid
      code
      quantity
      strategy
      type
    }
  }
`;
